import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import _get from "lodash.get";

import PageTemplate from "../templates/PageTemplate";

const HomePage = (props) => {
  const data = useStaticQuery(graphql`
    {
      page: contentJson(templateId: { eq: "home" }) {
        id
        seo {
          ...SEO
        }
        modularContent {
          ...ModularBlock
        }
      }
    }
  `);

  return <PageTemplate {...props} {..._get(data, "page")} />;
};

export default HomePage;
